<template>
  <div>
    <div
      v-for="(item, index ) in introductions"
      :key="index"
      :class="{'text-bold':index===0&&boldFirst}"
    >
      <!--      -->
      <b-icon
        v-if="index!==0||!boldFirst"
        icon="caret-right-fill"
      />
      {{ item }}
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'

export default @Component({})
class MemberIntroduction extends Vue {
  @Prop({
    required: true,
    default: ''
  }) introduction

  @Prop({ default: false }) boldFirst

  get introductions () {
    return this.introduction.split(/\n/g).map(item => item.trim()).filter(item => !!item)
  }
}
</script>

<style lang="scss" scoped>
.text-bold {
  font-weight: bold;
}
</style>
