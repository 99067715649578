<template>
  <b-carousel
    :interval="8000"
    controls
    indicators
  >
    <b-carousel-slide v-if="$route.name!=='Science'">
      <template #img>
        <img
          class="d-block img-fluid w-100 carousel-image"
          src="../assets/images/home/carousel_1.png"
          alt="image slot"
        >
      </template>
      <div class="carousel-title">
        Bridging the Gap between Global Biopharma & Medtech and Asia.
      </div>
      <div class="text-center">
        <router-link
          class="carousel-link"
          :to="{name: 'Science'}"
        >
          Financial Advisor
        </router-link>
      </div>
    </b-carousel-slide>
    <b-carousel-slide v-if="$route.name!=='FundManagement'">
      <template #img>
        <img
          class="d-block img-fluid w-100 carousel-image"
          src="../assets/images/home/carousel_2.png"
          alt="image slot"
        >
      </template>
      <div class="carousel-title">
        Navigating Entry into China and Japan Markets and Access to Additional Resources and Funding.
      </div>
      <div class="text-center">
        <router-link
          :to="{name: 'FundManagement'}"
          class="carousel-link"
        >
          YAFO Healthcare Fund
        </router-link>
      </div>
    </b-carousel-slide>
    <b-carousel-slide v-if="$route.name!=='Incubation'">
      <template #img>
        <img
          class="d-block img-fluid w-100 carousel-image"
          src="../assets/images/biocubate/background.jpg"
          alt="image slot"
        >
      </template>
      <div class="carousel-title">
        Incubate world leading bio-innovation for Asian patients！
      </div>
      <div class="text-center">
        <router-link
          :to="{name: 'Incubation'}"
          class="carousel-link"
        >
          YAFO Incubation
        </router-link>
      </div>
    </b-carousel-slide>
    <b-carousel-slide>
      <template #img>
        <img
          class="d-block img-fluid w-100 carousel-image"
          src="../assets/images/home/carousel_3.png"
          alt="image slot"
        >
      </template>
      <div class="carousel-title">
        Events & News
      </div>
      <div class="text-center">
        <router-link
          :to="{name: 'Events'}"
          class="carousel-link"
        >
          View More
        </router-link>
      </div>
    </b-carousel-slide>
  </b-carousel>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'

export default @Component({})
class Carousel extends Vue {
}
</script>

<style lang="scss" scoped>

</style>
