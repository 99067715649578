<template>
  <div>
    <div class="in-container">
      <carousel />
    </div>

    <div class="d-flex flex-column align-items-center items container">
      <div class="title">
        YAFO Incubation
      </div>
      <div class="divider">
        <img
          src="@/assets/images/divider.png"
          alt=""
        >
      </div>
      <div class="content text-center">
        With significant life sciences industry expertise and experience in drug development, manufacture, and sales, we
        are dedicated to identify, in-license, develop, and commercialize the high potential innovative drug assets and
        new technologies from worldwide. By leveraging our clinical, BD, and commercialization skills in local markets,
        we are able to accelerate our partnering assets’ development and promote best practices in China, Japan, India,
        and other Asia countries.
      </div>
    </div>
    <div class="in-container">
      <b-carousel>
        <b-carousel-slide>
          <template #img>
            <img
              class="d-block img-fluid w-100 carousel-image"
              src="../assets/images/biocubate/background_2.jpg"
              alt="image slot"
            >
          </template>
          <div class="carousel-title">
            Strategic alliance with world leading CROs to provide one-stop clinical support to our partners and pipeline
            assets.
          </div>
        </b-carousel-slide>
      </b-carousel>
    </div>
    <div class="d-flex flex-column align-items-center items container">
      <div class="title">
        Business Model
      </div>
      <div class="divider">
        <img
          src="@/assets/images/divider.png"
          alt=""
        >
      </div>
      <div class="content text-center">
        <img
          src="@/assets/images/biocubate/bizmodel.png"
          alt=""
        >
      </div>
    </div>
    <div class="d-flex flex-column align-items-center items container">
      <div class="title">
        Pipeline Strategy
      </div>
      <div class="divider">
        <img
          src="@/assets/images/divider.png"
          alt=""
        >
      </div>
      <div class="content text-center">
        <img
          src="@/assets/images/biocubate/pipeline_strategy.png"
          alt=""
        >
      </div>
    </div>
    <div class="d-flex flex-column align-items-center items container">
      <div class="title">
        Management team
      </div>
      <div class="divider">
        <img
          src="@/assets/images/divider.png"
          alt=""
        >
      </div>
      <div class="content text-center">
        <b-row>
          <b-col
            v-for="(member,index) in members"
            :key="member.id"
            class="member mb-4 mb-sm-0"
            sm="6"
          >
            <div
              class="d-flex  align-items-center flex-wrap justify-content-center text-center text-sm-left"
              :class="{'flex-sm-row-reverse text-sm-right':index%2===1}"
            >
              <img
                class="avatar"
                :src="member.avatar.url | getSource"
                alt=""
              >
              <div class="info mt-4 mt-sm-0">
                <div class="name">
                  {{ member.name }}
                </div>
                <div class="position">
                  {{ member.position }}
                </div>
              </div>
            </div>
            <div class="desc">
              {{ member.introduction }}
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center items container">
      <div class="content text-center">
        Please submit your company presentation to us, if you are interested in partnering your technology or assets in
        China.
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import Carousel from '@/components/Carousel'
import { getBiocubateManagementTeam } from '@/api/team'

export default @Component({
  components: { Carousel }
})
class Incubation extends Vue {
  members = []

  mounted () {
    this.getBiocubateManagementTeam()
  }

  async getBiocubateManagementTeam () {
    const data = await getBiocubateManagementTeam()
    this.members = data
  }
}
</script>

<style lang="scss" scoped>
.member {

  .avatar {
    width: 180px;
    height: 180px;
    border-radius: 50%;
  }

  .info {
    margin: 0 1rem;
    flex: 1;

    @media (max-width: 479px) {
      flex: auto;
    }

    .name {
      font-size: 1rem;
      font-weight: bold;
    }

    .position {
      font-size: 1rem;
      font-weight: bold;
    }
  }

  .desc {
    margin-top: 1.5rem;
    text-align: left;
    line-height: 1.5;
    white-space: pre-line;
  }
}
</style>
