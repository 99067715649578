<template>
  <div>
    <div class="in-container">
      <carousel />
    </div>
    <div class="d-flex flex-column align-items-center items container">
      <div class="title text-center">
        About Our Fund
      </div>
      <div class="divider">
        <img
          src="@/assets/images/divider.png"
          alt=""
        >
      </div>
      <b-row class="content">
        <b-col
          cols="12"
          sm="7"
        >
          <p>
            YAFO Capital Healthcare Fund actively invests in innovative companies within all areas of life sciences
            including biotechnology, medical devices, diagnostics, genomics and digital health, partnering with those
            that have strong growth potential in China/Japan market. Our mission is to bridge the gap between
            undercapitalized life sciences projects, companies, and investors through direct investment and/or
            licensing/co-development arrangements.
          </p>
          <p>
            Our exit strategy is that we leverage our network with established pharmaceutical companies in China and
            Japan to seek potential exit or partnering.
          </p>
          <p>
            Our expert team of international biopharma executives, advisors and investors offer cross-cultural expertise
            while navigating entry into Asian markets and access to additional resources and funds.
          </p>
          <p>
            Funding technology enterprises with high growth potential and supporting their leaders enables us to boost
            innovation in China/Japan life sciences industry, a huge market to explore.
          </p>
          <p>
            • Investing directly through our own growth capital fund
          </p>
          <p>
            • Structured buy-out fund for mature stage companies
          </p>
        </b-col>
        <b-col
          cols="12"
          sm="5"
        >
          <img
            class="w-100"
            src="https://taotechstatic.oss-cn-shanghai.aliyuncs.com/yafo/yafo-2019/investment/investment-4.jpg"
            alt=""
          >
        </b-col>
      </b-row>
    </div>
    <div class="d-flex flex-column align-items-center items container">
      <div class="title text-center">
        YAFO Investment Fund
      </div>
      <div class="divider">
        <img
          src="@/assets/images/divider.png"
          alt=""
        >
      </div>
      <div class="content text-center">
        <p>
          YAFO is a licensed fund manager under the Asset Management Association of China since 2015. YAFO Healthcare
          Fund actively invests in innovative companies including biotechnology, medical devices, diagnostics, genomics
          and others.
        </p>
        <p>
          YAFO Buy-out fund, along with pharma companies, invest in global or domestic late stage assets through
          license-in or M&A.
        </p>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center items container">
      <div class="title text-center">
        YAFO Growth Fund
      </div>
      <div class="divider">
        <img
          src="@/assets/images/divider.png"
          alt=""
        >
      </div>
      <div class="content text-center">
        <p>
          Global leading technology: gene therapy, immunotherapy and other fields
        </p>
        <p>
          Addresses clinical unmet needs
        </p>
        <p>
          Potential to become best-in-class or first-in-class products
        </p>
        <p>
          Avoid overcrowded sectors
        </p>
        <b-row>
          <b-col
            v-for="(item,index) in growth"
            :key="index"
            class="growth mt-2 mb-2"
            cols="6"
            sm="4"
          >
            <div>
              <img
                :src="item.image"
                alt=""
              >
            </div>
            <div class="desc mt-2">
              {{ item.desc }}
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center items container">
      <div class="title text-center">
        YAFO Buy-out Fund
      </div>
      <div class="divider">
        <img
          src="@/assets/images/divider.png"
          alt=""
        >
      </div>
      <div class="content text-center">
        <p>YAFO & Chinese Pharmas co-establish buy-out fund for specific pharma products </p>
        <p>YAFO is responsible for selecting ph2 and later stage innovative pharma products </p>
        <p>
          Chinese Pharmas’ strategic resources and investment can reduce the risk of asset development and
          commercialization
        </p>
        <div class="mt-4">
          <img
            src="/images/fund/7.png"
            alt=""
          >
        </div>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center items container">
      <div class="title text-center">
        YAFO-Unifortune Fund Team & Record
      </div>
      <div class="divider">
        <img
          src="@/assets/images/divider.png"
          alt=""
        >
      </div>
      <div class="content ">
        <b-row>
          <b-col
            v-for="member in members"
            :key="member.id"
            cols="12"
            sm="6"
          >
            <b-row class="member mb-4">
              <b-col>
                <div>
                  <img
                    class="avatar"
                    :src="member.avatar.url | getSource"
                    alt=""
                  >
                </div>
              </b-col>
              <b-col
                cols="12"
                sm="8"
              >
                <div class="name mt-3 mt-sm-0 text-center text-sm-left">
                  {{ member.name }} | {{ member.position }}
                </div>
                <member-introduction
                  class="introduction mt-2"
                  :introduction="member.introduction"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center items container">
      <div class="title text-center">
        Investment Portfolio
      </div>
      <div class="divider">
        <img
          src="@/assets/images/divider.png"
          alt=""
        >
      </div>
      <div class="content text-center">
        <b-row>
          <b-col
            v-for="(item,index) in portfolio"
            :key="index"
            class="mb-2 mt-2"
            cols="6"
            sm="2"
          >
            <img
              class="portfolio"
              :src="item"
              alt=""
            >
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center items container">
      <div class="content text-center">
        <p class="font-weight-bold font-size-16 color-main">
          Contact us (qli@yafocapital.com) to learn more about the potential of your product in China Market.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import Carousel from '@/components/Carousel'
import MemberIntroduction from '@/components/MemberIntroduction'
import { getUnifortuneFundTeam } from '@/api/team'

export default @Component({
  components: {
    Carousel,
    MemberIntroduction
  }
})
class FundManagement extends Vue {
  growth = [{
    image: '/images/fund/1.png',
    desc: 'Gene therapy'
  }, {
    image: '/images/fund/2.png',
    desc: 'immunotherapy'
  }, {
    image: '/images/fund/3.png',
    desc: 'Innovative platform'
  }, {
    image: '/images/fund/4.png',
    desc: 'Segmentation leaders'
  }, {
    image: '/images/fund/5.png',
    desc: 'Vaccine'
  }, {
    image: '/images/fund/6.png',
    desc: 'MedTech & IVD'
  }]

  portfolio = [
    'images/fund/c01.png',
    'images/fund/c02.png',
    'images/fund/c03.png',
    'images/fund/c04.png',
    'images/fund/c05.png',
    'images/fund/c06.png',
    'images/fund/c07.png',
    'images/fund/c08.png',
    'images/fund/c09.png',
    'images/fund/c10.png',
    'images/fund/c11.png',
    'images/fund/c12.png',
    'images/fund/c13.png',
    'images/fund/c14.png'
  ]

  members = []

  mounted () {
    this.getUnifortuneFundTeam()
  }

  async getUnifortuneFundTeam () {
    const data = await getUnifortuneFundTeam()
    this.members = data
  }
}
</script>

<style lang="scss" scoped>
.border-outside {
  img {
    border: 1px solid #ddd;
  }
}

.growth {
  .desc {
    font-size: 15px;
    font-weight: bold;
  }
}

.portfolio {
  background: white;
  border: 1px solid rgba(#e49100, 0.3);
  border-radius: 2px;
  overflow: hidden;
}

.member {
  .avatar {
    border-radius: 50%;
  }

  .name, .position {
    color: #e49100;
    font-size: 1rem;
    font-weight: bold;
  }

  .introduction {
    white-space: pre-line;
  }
}
</style>
